import { autoinject, PLATFORM } from "aurelia-framework";
import { EventAggregator } from "aurelia-event-aggregator";
require('./style/view-port.scss')


export enum ViewPorts {
  desktop = 5,
  laptop = 4,
  tablet = 3,
  phablet = 2,
  mobile = 1,
}
@autoinject
export class ViewPort {

  public deviceSize: ViewPorts = ViewPorts.mobile;

  constructor(private eventService: EventAggregator) {

    window.addEventListener("resize", ev => {
      this.deviceSize = this.computDeviceSize();
    });
    this.deviceSize = this.computDeviceSize();
  }

  private computDeviceSize(): ViewPorts {
    var elm = window.document.createElement("div");
    try {
      elm.id = "viewport-tester";
      window.document.body.appendChild(elm);

      elm.className = "desktop";
      if (elm.offsetParent !== null) {
        return ViewPorts.desktop;
      }

      elm.className = "laptop";
      if (elm.offsetParent !== null) {
        return ViewPorts.laptop;
      }

      elm.className = "tablet";
      if (elm.offsetParent !== null) {
        return ViewPorts.tablet;
      }
      elm.className = "phablet";
      if (elm.offsetParent !== null) {
        return ViewPorts.phablet;
      }
      return ViewPorts.mobile;

    } catch (ex) {
    } finally {
      window.document.body.removeChild(elm);
    }
    return ViewPorts.mobile;
  }
}


