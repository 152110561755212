import { validate as innerValidate, ValidationError } from "class-validator";
import { Binding, AccessScope, BindingBehavior, Expression, AccessMember } from "aurelia-binding";
import { ValidationTriggers } from "resources/behaviour/bindValidationResult";

export * from "class-validator";
export * from "resources/behaviour/validators";

export function validateBinding(binding: Binding): Promise<ValidationError[]> {
  // autoValidation
  return validateInternal(binding['source'].bindingContext, [binding], ValidationTriggers.AutoValidation);
}

export async function addBindingValidation(binding: Binding) {
  let vm: any = binding.source.bindingContext;
  //let vm:any = scope.controller.viewModel;
  vm.__validationerrorbindings_expressions__ = vm.__validationerrorbindings_expressions__ || [];
  vm.__validationerrorbindings_expressions__.push(binding);

  // also add binding to parent vm so validation can be triggered by the parent later
  let parent = (binding.source as any).container.parent;
  while (parent) {
    if (parent.viewModel && parent.viewModel.__validationerrorbindings_expressions__) {
      if (binding.source.bindingContext.validationMessages === undefined
          && !parent.viewModel.__validationerrorbindings_expressions__.some((x: any) => x.target.inputCounter === (binding as any).target.inputCounter)) {
        parent.viewModel.__validationerrorbindings_expressions__.push(binding);
      }
    }

    parent = parent.parent;
  }


  binding['target'].__validationerrorbindings_targetbinding__ = binding['target'].__validationerrorbindings_targetbinding__ || [];
  binding['target'].__validationerrorbindings_targetbinding__.push(binding);

  binding['__ValidateBindingBehavior'] = binding.updateSource;
  binding.updateSource = function (v) {
    binding['__ValidateBindingBehavior'].apply(this, [v]);

    if (binding && binding.sourceExpression instanceof BindingBehavior) {
      validateBinding(binding);
    }
  }

  await validateInternal(vm, [], ValidationTriggers.AutoValidation);
}

export function removeBindingValidation(binding: Binding) {
  let vm: any = binding.source.bindingContext;
  vm.__validationerrorbindings_expressions__ = vm.__validationerrorbindings_expressions__ || [];
  vm.__validationerrorbindings_expressions__ = vm.__validationerrorbindings_expressions__.filter(x => x != binding);

  binding['target'].__validationerrorbindings_targetbinding__ = binding['target'].__validationerrorbindings_targetbinding__ || [];
  binding['target'].__validationerrorbindings_targetbinding__ = binding['target'].__validationerrorbindings_targetbinding__.filter(x => x != binding);

  binding.updateSource = binding['__ValidateBindingBehavior'];
  delete binding['__ValidateBindingBehavior'];
}

export async function validateBoundType(model: Object): Promise<ValidationError[]> {
  var vm = model as any;
  vm.__validationerrorbindings_targetbinding__ = vm.__validationerrorbindings_targetbinding__ || [];

  let bindings: Binding[] = vm.__validationerrorbindings_targetbinding__;
  var results = [];
  for (var i = 0; i < bindings.length; i++) {
    var r = await validateBinding(bindings[i]);
    results = [...results, ...r];
  }
  return results;
}

function debounceValidation(model: Object): Promise<ValidationError[]> {
  return new Promise<ValidationError[]>((resolve, reject) => {
    model['__validationerrorbindings__validate_resolves__'] = model['__validationerrorbindings__validate_resolves__'] || [];
    model['__validationerrorbindings__validate_resolves__'].push({ resolve, reject });

    if (model['__validationerrorbindings_timeout__']) {
      clearTimeout(model['__validationerrorbindings_timeout__']);
    }

    model['__validationerrorbindings_timeout__'] = setTimeout(() => {
      innerValidate(model).then(r => {
        var callbacks = model['__validationerrorbindings__validate_resolves__'];
        model['__validationerrorbindings__validate_resolves__'] = [];
        callbacks.forEach(cb => {
          cb.resolve(r);
        });
      }).catch(e=>{
        var callbacks = model['__validationerrorbindings__validate_resolves__'];
        model['__validationerrorbindings__validate_resolves__'] = [];
        callbacks.forEach(cb => {
          cb.reject(e);
        });
      })
    }, 10);
  });
}

async function validateInternal(model: Object, bindings: Binding[], trigger: ValidationTriggers): Promise<ValidationError[]> {
  let vm = model as any;
  vm.__validationerrorbindings_expressions__ = vm.__validationerrorbindings_expressions__ || [];
  let allBindings: Binding[] = vm.__validationerrorbindings_expressions__;

  if (trigger === ValidationTriggers.ManualValidation) {
    // also trigger validation of other bindings to show validation messages
    for (let i = 0; i < allBindings.length; i++) {
      const binding: any = allBindings[i];
      if (binding.target.__validationerrorbindings_expressions__) {
        await validate(binding.target);
      }
    }
  }

  return debounceValidation(model).then((e) => {
    allBindings.forEach(binding => {
      if (binding && binding.sourceExpression instanceof BindingBehavior) {
        if (binding['target'] && binding['target']['__validationerrorbindings__']) {
          var result = extractErrors(e, binding.sourceExpression.expression);
          (binding['target']['__validationerrorbindings__'] as Array<string>).forEach(b => {
            binding['target'][b] = result;
          })
        }
      }
    });

    if (vm['__validationerrorbindings__']) {
      (vm['__validationerrorbindings__'] as Array<string>).forEach(b => {
        vm[b] = e;
      })
    }

    bindings.forEach(binding => {
      if (binding && binding.sourceExpression instanceof BindingBehavior) {
        if (binding['target'] && binding['target']['__validationerrorbindings_fired__']) {
          (binding['target']['__validationerrorbindings_fired__'] as Array<{ prop: string, trigger: ValidationTriggers }>).forEach(b => {
            if (trigger == b.trigger) {
              binding['target'][b.prop] = true;
            }
          })
        }
      }
    });

    if (vm['__validationerrorbindings_fired__']) {
      (vm['__validationerrorbindings_fired__'] as Array<{ prop: string, trigger: ValidationTriggers }>).forEach(b => {
        if (trigger == b.trigger) {
          vm[b.prop] = true;
        }
      })
    }

    return e;
  });
}

export function validate(model: Object): Promise<ValidationError[]> {
  // manual validation
  let vm = model as any;
  vm.__validationerrorbindings_expressions__ = vm.__validationerrorbindings_expressions__ || [];
  let bindings: Binding[] = vm.__validationerrorbindings_expressions__;
  return validateInternal(model, bindings, ValidationTriggers.ManualValidation);
}

function extractErrors(errors: ValidationError[], expression: Expression): ValidationError {
  if (expression instanceof AccessMember) {
    var error = extractErrors(errors, expression.object)
    if (error) return error.children.find(x => x.property === expression.name);
  } else if (expression instanceof AccessScope) {
    return errors.find(x => x.property === expression.name) || null;
  }
  return null;
}

function extractModel(scope: any, expression: Expression): { scope: any, property: () => any, propertyName: string } {
  if (expression instanceof AccessMember) {
    var member = extractModel(scope, expression.object)
    return {
      scope: member.property(),
      property: () => member.property[expression.name],
      propertyName: expression.name
    };
  } else if (expression instanceof AccessScope) {
    return {
      scope,
      property: () => scope[expression.name],
      propertyName: expression.name
    }
  }
  return null;
}